
.app-wrapper {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 700px;
  float: left; /* Add this line to float the box to the left */
}

.App {
  padding-top: 20px;
}

